import React, { Component } from 'react'
import SignLayout from '../layouts/SignLayout';

import SEO from '../components/SEO/SEO';
import Founder from '../containers/Founder/Founder';
let title = "Profit sharing program l GOJO Founder's Fund"
let descriptions = "GOJO Founder's Fund is 12-month profit sharing program that gives back a portion of our earnings on each eligible ride to drivers."
let scriptFaceBookPixel = `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function()
{n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1088234585047003');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=1088234585047003&ev=PageView&noscript=1"
/></noscript>
`
class Driver extends Component {

    render() {
        return (
            <SignLayout>
              <SEO title={title} description={descriptions} scriptFaceBookPixel={scriptFaceBookPixel}/>
              <Founder />
            </SignLayout>
        )
    }
}

export default Driver;