import React from 'react'
import { injectIntl } from "gatsby-plugin-intl"

const listLanguageRightToLeft = ['jo-ar', 'sa-ar']

const DirectionLanguage =  ({ intl, children }) => {
  let dir = 'ltr'
  const locale = intl.locale || ''
  
  if( locale && listLanguageRightToLeft.indexOf(locale) >= 0 ) {
    dir = 'rtl'
  }

  if(children) {
    return (
      React.cloneElement(
        React.Children.only(
          children
        ), 
        { dir: dir }
      ) 
    )
  } 
  return null
}
export default injectIntl(DirectionLanguage)